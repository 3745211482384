export type MediaContentType = {
    type: string | 'video' | 'img';
    url: string;
    fit?: string;
    hint?: string;
    source?: string;
    preview?: string;
    index?: number;
}
type MediaOptionsType = {
    imagesIds?: number[];
    videos?: { url: string }[];
    userImagesIds?: number[];
}

export function concatMediaContent () {
  function initMediaArrays (options: MediaOptionsType) {
    const isVideo = Boolean(options.videos && options.videos.length);
    const isImage = Boolean(options.imagesIds && options.imagesIds.length);

    const contentImg: MediaContentType[] = [];
    const contentVideo: MediaContentType[] = [];

    if (isVideo) {
            options.videos!.forEach((t, index) => {
              let link = `https://www.youtube.com/embed/${t.url}?wmode=opaque&autoplay=0&enablejsapi=1&modestbranding=1&showinfo=0&rel=0&controls=1`;
              if (String(t.url || '').includes('https://')) {
                link = t.url;
              }
              //________ для вк
              if (String(t.url || '').includes('vk.com')) {
                  const videoIds = t.url.split('-')?.[1]
                  const videoOid = videoIds.split('_')?.[0]
                  const videoId = videoIds.split('_')?.[1]
                  link = `https://vk.com/video_ext.php?oid=-${videoOid}&id=${videoId}&autoplay=0&loop=1&volume=0`
              }
              //
              contentVideo.push({
                type: 'video',
                url: link,
                source: t.url,
                preview: String(options?.imagesIds?.[index] || options?.imagesIds?.[0] || '') // подмешиваем изображниея для превью (для 1 видео 1 картинка и т.д)
              });
            });
    }
    if (isImage) {
            options.imagesIds!.forEach((t) => {
              contentImg.push({
                type: 'img',
                url: String(t)
              });
            });
    }

    return {
      contentImg,
      contentVideo
    };
  }

  function concatArrays (array1: MediaContentType[], array2: MediaContentType[]) {
    const result: MediaContentType[] = [];

    for (let i = 0; i < Math.max(array1.length, array2.length); i += 1) {
      if (i < array1.length) {
        result.push(array1[i]);
      }
      if (i < array2.length) {
        result.push(array2[i]);
      }
    }
    return result;
  }

  function getLinkForPreview (source: string) {
    //________ для вк ЗАГЛУШКА!
    if (String(source || '').includes('vk.com')) {
      return ''
    }
    //
    if (String(source || '').includes('https://')) {
      return ""
    }
    return `https://img.youtube.com/vi/${source}/1.jpg`;
  }

  return {
    concatArrays,
    initMediaArrays,
    getLinkForPreview
  };
}
