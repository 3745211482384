<template>
  <div
    class="header-input-search"
    :class="{
      'open': isOpen,
      'loading': isLoading,
    }"
  >
    <label
      class="header-input-search__input"
      v-on:keyup.enter="routePageSearch"
    >
      <input
        v-model="searchValue"
        type="text"
        placeholder="Поиск"
      />
      <img
        v-if="!isLoading"
        src="~/assets/img/svg/common/search.svg"
        alt="поиск"
      />
      <img
        v-if="isLoading"
        src="~/assets/img/svg/common/spinner.svg"
        alt="загрузка"
      />
    </label>

    <template v-if="isOpen">
      <div class="header-input-search__dropdown">
        <div class="header-input-search__dropdown-scroll scroll-customize-medium">
          <div
            v-if="resultList.length > 0"
            v-for="(result) in resultList"
            class="--section"
          >
            <div
              v-if="result.items.length > 0"
              class="--head"
            >
              <p>{{ result.name }} <sup>{{result.total}}</sup></p>
              <nuxt-link
                :to="`/catalogsearch/result?q=${ searchValue }&type=${ result.section }`"
                @click="closeDropDown"
              >
                Показать все
              </nuxt-link>
            </div>
            <div
              v-if="result.items.length > 0"
              class="--body"
            >
              <ul>
                <li v-for="(item) in result.items">
                  <nuxt-link
                    v-html="item.name || item.title"
                    :to="item.url"
                    @click="closeDropDown"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="resultList.length <= 0 && !isLoading"
            class="--not-found"
          >
            По вашему запросу ничего не найдено
          </div>
        </div>
      </div>
      <div class="header-input-search__close" @click="closeDropDown"/>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useNuxtApp } from 'nuxt/app';

const {
  $api
} = useNuxtApp();
const $router = useRouter();

const isOpen = ref(false);
const isLoading = ref(false);
const searchValue = ref('');
const resultList: any = ref([]);
let timeOutStartResult: any = null;

const closeDropDown = () => {
  isOpen.value = false;

  resultList.value = [];
  searchValue.value = "";
}

const getResultList = async () => {
  if (!searchValue.value) {
    return
  }

  const res = await $api.agent.get(`/catalog/search?query=${ searchValue.value }&per-page=8`).then((res: any) => {
    return res._data
  }).catch(() => {
    return {}
  });
  let results: any[] = [];
  Object.keys((res?.results || {})).map((key: string) => {
    const _item = res?.results?.[key];
    if (Number.parseFloat(String(_item?.total || '0')) > 0) {
      results.push({
        ..._item,
        resultKey: key
      })
    }
  });

  const search: string = String(searchValue.value).toLowerCase();
  const maxCountItems: number = Boolean(results.length > 1) ? 4 : 8;
  results = [...(results || [])]
    .map((item: any) => {
      let _items = [...(item.items || [])];
      if (item.resultKey === 'brands') {
        _items = _items.filter((t: any) => {
          const nameSmall: string = String(t?.name).toLowerCase();
          return Boolean(search === nameSmall)
        });
      };

      return {
        ...item,
        section: item.resultKey,
        items: _items.splice(0, maxCountItems)
      }
    })

  resultList.value = results;
  isOpen.value = true;
  isLoading.value = false;
}
const startResultList = async () => {
  clearTimeout(timeOutStartResult);
  isLoading.value = Boolean(searchValue.value);
  if (!searchValue.value) {
    isOpen.value = false;
  }
  timeOutStartResult = setTimeout(async () => {
    await getResultList();
  }, 1000);
}
watch(() => searchValue.value, startResultList);

const routePageSearch = () => {
  if ((searchValue.value || '').length < 2) {
    return
  }
  $router.push(`/catalogsearch/result/?q=${ searchValue.value }`);
  closeDropDown();

  resultList.value = [];
  searchValue.value = '';
  isLoading.value = false;
  clearTimeout(timeOutStartResult);
}

onMounted(async () => {
  await getResultList();
});
</script>

<style lang="scss">
.header-input-search {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: relative;

  &.open {
    .header-input-search__input {
      z-index: 51;
    }
  }

  &.loading .header-input-search__input img {
    opacity: 0.5;
    width: 16px;
    height: 16px;
  }
}
.header-input-search__input {
  display: flex;
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  height: 40px;
  position: relative;

  img {
    position: absolute;
    top: 50%; right: 6px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    padding-right: 36px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }

  &:hover {
    background: #EBEBEB;
  }
}
.header-input-search__dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 51;
  top: calc(100% + 4px);
  right: 0;
  width: 100vw;
  max-width: max-content;
  max-height: calc(100vh - 150px);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #B8B8B8;
  background: #FFF;
}
.header-input-search__dropdown-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 8px;
  box-sizing: border-box;
  position: relative;

  .--section {}

  .--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #F4F4F4;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    gap: 20px;
  }

  .--head sup {
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    transform: translateX(6px);
  }

  .--head a {
    color: #989898;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .--body {
    padding-top: 14px;
    box-sizing: border-box;
  }

  .--body ul li {
    margin-top: 14px;

    &:first-child {
      margin-top: 0;
    }
  }

  .--body a {
    padding: 8px 16px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
  .--body a b {
    font-weight: 600;
  }

  .--not-found {
    color: #989898;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  & > * {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.header-input-search__close {
  position: fixed;
  inset: 0;
  z-index: 50;
}
</style>
