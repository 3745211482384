import { default as _91_46_46_46cms_93nJVe2dBE3CMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93T8H7Vk9dlUMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexHPwVORAX0GMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93RdDJuW7aM2Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/akcii/[...slug].vue?macro=true";
import { default as indexdkqSNaitiVMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93qIqfNj6mjzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/articles/[...slug].vue?macro=true";
import { default as indexbG85pZ7uQyMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/articles/index.vue?macro=true";
import { default as indexzOXUHFeGMpMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/authorization/index.vue?macro=true";
import { default as indexzYfhxrq44kMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/cart/index.vue?macro=true";
import { default as indexI0Rpci340AMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93wdkajoAZhXMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalog/[...slug].vue?macro=true";
import { default as indexsMxxGGacHzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_933e5qNPQQZnMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resulthO61BvdsFTMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalogsearch/result.vue?macro=true";
import { default as indexEVcDfWvoNEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93TciOrFd3ccMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93A6dkDkYb8bMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/fabriki/[...slug].vue?macro=true";
import { default as indexRyQlne6HBBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93FjRb188QHTMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/product/[...slug].vue?macro=true";
import { default as indexE9z4E0Ib86Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/profile/index.vue?macro=true";
import { default as indexM6CXI9ZRJuMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/profile/orders/index.vue?macro=true";
import { default as indexIffcMRomYnMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93S5ZW81r7YwMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/sets/[...slug].vue?macro=true";
import { default as indexg0KNEgYfoTMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93nJVe2dBE3CMeta?.name ?? "cms",
    path: _91_46_46_46cms_93nJVe2dBE3CMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93nJVe2dBE3CMeta || {},
    alias: _91_46_46_46cms_93nJVe2dBE3CMeta?.alias || [],
    redirect: _91_46_46_46cms_93nJVe2dBE3CMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93T8H7Vk9dlUMeta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93T8H7Vk9dlUMeta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93T8H7Vk9dlUMeta || {},
    alias: _91_46_46_46slug_93T8H7Vk9dlUMeta?.alias || [],
    redirect: _91_46_46_46slug_93T8H7Vk9dlUMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexHPwVORAX0GMeta?.name ?? "3d-projects",
    path: indexHPwVORAX0GMeta?.path ?? "/3d-projects",
    meta: indexHPwVORAX0GMeta || {},
    alias: indexHPwVORAX0GMeta?.alias || [],
    redirect: indexHPwVORAX0GMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RdDJuW7aM2Meta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93RdDJuW7aM2Meta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93RdDJuW7aM2Meta || {},
    alias: _91_46_46_46slug_93RdDJuW7aM2Meta?.alias || [],
    redirect: _91_46_46_46slug_93RdDJuW7aM2Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexdkqSNaitiVMeta?.name ?? "akcii",
    path: indexdkqSNaitiVMeta?.path ?? "/akcii",
    meta: indexdkqSNaitiVMeta || {},
    alias: indexdkqSNaitiVMeta?.alias || [],
    redirect: indexdkqSNaitiVMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93qIqfNj6mjzMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93qIqfNj6mjzMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93qIqfNj6mjzMeta || {},
    alias: _91_46_46_46slug_93qIqfNj6mjzMeta?.alias || [],
    redirect: _91_46_46_46slug_93qIqfNj6mjzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexbG85pZ7uQyMeta?.name ?? "articles",
    path: indexbG85pZ7uQyMeta?.path ?? "/articles",
    meta: indexbG85pZ7uQyMeta || {},
    alias: indexbG85pZ7uQyMeta?.alias || [],
    redirect: indexbG85pZ7uQyMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzOXUHFeGMpMeta?.name ?? "authorization",
    path: indexzOXUHFeGMpMeta?.path ?? "/authorization",
    meta: indexzOXUHFeGMpMeta || {},
    alias: indexzOXUHFeGMpMeta?.alias || [],
    redirect: indexzOXUHFeGMpMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexzYfhxrq44kMeta?.name ?? "cart",
    path: indexzYfhxrq44kMeta?.path ?? "/cart",
    meta: indexzYfhxrq44kMeta || {},
    alias: indexzYfhxrq44kMeta?.alias || [],
    redirect: indexzYfhxrq44kMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0Rpci340AMeta?.name ?? "cart-success",
    path: indexI0Rpci340AMeta?.path ?? "/cart/success",
    meta: indexI0Rpci340AMeta || {},
    alias: indexI0Rpci340AMeta?.alias || [],
    redirect: indexI0Rpci340AMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wdkajoAZhXMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93wdkajoAZhXMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93wdkajoAZhXMeta || {},
    alias: _91_46_46_46slug_93wdkajoAZhXMeta?.alias || [],
    redirect: _91_46_46_46slug_93wdkajoAZhXMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexsMxxGGacHzMeta?.name ?? "catalog",
    path: indexsMxxGGacHzMeta?.path ?? "/catalog",
    meta: indexsMxxGGacHzMeta || {},
    alias: indexsMxxGGacHzMeta?.alias || [],
    redirect: indexsMxxGGacHzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_933e5qNPQQZnMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_933e5qNPQQZnMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_933e5qNPQQZnMeta || {},
    alias: _91_46_46_46slug_933e5qNPQQZnMeta?.alias || [],
    redirect: _91_46_46_46slug_933e5qNPQQZnMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resulthO61BvdsFTMeta?.name ?? "catalogsearch-result",
    path: resulthO61BvdsFTMeta?.path ?? "/catalogsearch/result",
    meta: resulthO61BvdsFTMeta || {},
    alias: resulthO61BvdsFTMeta?.alias || [],
    redirect: resulthO61BvdsFTMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexEVcDfWvoNEMeta?.name ?? "clear-page-slider",
    path: indexEVcDfWvoNEMeta?.path ?? "/clear-page-slider",
    meta: indexEVcDfWvoNEMeta || {},
    alias: indexEVcDfWvoNEMeta?.alias || [],
    redirect: indexEVcDfWvoNEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TciOrFd3ccMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93TciOrFd3ccMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93TciOrFd3ccMeta || {},
    alias: _91_46_46_46slug_93TciOrFd3ccMeta?.alias || [],
    redirect: _91_46_46_46slug_93TciOrFd3ccMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93A6dkDkYb8bMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93A6dkDkYb8bMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93A6dkDkYb8bMeta || {},
    alias: _91_46_46_46slug_93A6dkDkYb8bMeta?.alias || [],
    redirect: _91_46_46_46slug_93A6dkDkYb8bMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexRyQlne6HBBMeta?.name ?? "fabriki",
    path: indexRyQlne6HBBMeta?.path ?? "/fabriki",
    meta: indexRyQlne6HBBMeta || {},
    alias: indexRyQlne6HBBMeta?.alias || [],
    redirect: indexRyQlne6HBBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93FjRb188QHTMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93FjRb188QHTMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93FjRb188QHTMeta || {},
    alias: _91_46_46_46slug_93FjRb188QHTMeta?.alias || [],
    redirect: _91_46_46_46slug_93FjRb188QHTMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexE9z4E0Ib86Meta?.name ?? "profile",
    path: indexE9z4E0Ib86Meta?.path ?? "/profile",
    meta: indexE9z4E0Ib86Meta || {},
    alias: indexE9z4E0Ib86Meta?.alias || [],
    redirect: indexE9z4E0Ib86Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexM6CXI9ZRJuMeta?.name ?? "profile-orders",
    path: indexM6CXI9ZRJuMeta?.path ?? "/profile/orders",
    meta: indexM6CXI9ZRJuMeta || {},
    alias: indexM6CXI9ZRJuMeta?.alias || [],
    redirect: indexM6CXI9ZRJuMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIffcMRomYnMeta?.name ?? "recently",
    path: indexIffcMRomYnMeta?.path ?? "/recently",
    meta: indexIffcMRomYnMeta || {},
    alias: indexIffcMRomYnMeta?.alias || [],
    redirect: indexIffcMRomYnMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93S5ZW81r7YwMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93S5ZW81r7YwMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93S5ZW81r7YwMeta || {},
    alias: _91_46_46_46slug_93S5ZW81r7YwMeta?.alias || [],
    redirect: _91_46_46_46slug_93S5ZW81r7YwMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexg0KNEgYfoTMeta?.name ?? "wishlist",
    path: indexg0KNEgYfoTMeta?.path ?? "/wishlist",
    meta: indexg0KNEgYfoTMeta || {},
    alias: indexg0KNEgYfoTMeta?.alias || [],
    redirect: indexg0KNEgYfoTMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250124101357/pages/wishlist/index.vue").then(m => m.default || m)
  }
]